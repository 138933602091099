const selectors = {
  oneTime: '[data-option-onetime]',
  subSave: '[data-option-subsave]',
  plansDropdown: '[data-plans-dropdown]',
  rechargeSelect: '.js-recharge-select',
  discountValue: '[data-label-discount]'
}

class widgetRecharge extends HTMLElement {
  constructor() {
    super()

    this.rechargeSelect = this.querySelector(selectors.rechargeSelect)

    this.config = {
      productId: this.dataset.prodId,
      injectionParent: this,
      injectionMethod: 'prepend',
      type: 'injected',
      injectFn: (node, target) => {
      },
      selectors: {
        price: ['.product-item__price', '.product-item__compare'],
        variant: ['.product-form__variants']
      }
    };

    this.originalComparePrice = null;

    this.addDataToReChargeWidget()
    this.handleSelectChange()
  }

  addDataToReChargeWidget = () => {
    if (window.ReChargeWidget) {
      let is_recharge = false
      window.ReChargeWidget.api.fetchWidgetData().then((data) => {
        const {storeSettings, widgetSettings, products} = data

        this.customSelectWidget(storeSettings, widgetSettings)
      })
    } else {
      setTimeout(this.addDataToReChargeWidget, 1000);
    }
  }

  customSelectWidget = (storeSettings, widgetSettings) => {

    const {store_currency} = storeSettings
    const {onetime_message} = widgetSettings

    window.ReChargeWidget?.api?.fetchProduct(this.dataset.prodId).then((product) => {
      if (!this.rechargeSelect || !product.in_recharge) return

      const {selling_plan_groups, external_product_id} = product



      const oneTimePurchaseOption = document.createElement('option')

      window.ReChargeWidget.createWidget(this.config);

      oneTimePurchaseOption.setAttribute('data-plan-option', onetime_message)
      oneTimePurchaseOption.setAttribute('value', '')
      oneTimePurchaseOption.textContent = onetime_message
      this.rechargeSelect.appendChild(oneTimePurchaseOption)

      selling_plan_groups.forEach(group => {


        group.selling_plans.forEach(plan => {
          const {selling_plan_name, selling_plan_id, price_adjustments_value, price_adjustments_value_type} = plan
          const subscribeOption = document.createElement('option')

          subscribeOption.setAttribute('data-plan-option', selling_plan_name)
          if (price_adjustments_value_type === 'percentage') {
            subscribeOption.setAttribute('data-plan-value', `${price_adjustments_value}%`)
          }
          subscribeOption.setAttribute('value', selling_plan_id)
          subscribeOption.textContent = selling_plan_name

          this.rechargeSelect.appendChild(subscribeOption)
        });
      });

      if (this.dataset.prodAvailable === 'false') {
        this.rechargeSelect.setAttribute('disabled', 'disabled')
      }
      this.classList.remove('hidden')
    })
  }

  // Function to update comparison price
  handleSelectChange = () => {
    if (!this.rechargeSelect) return
  
    const widget = this
    this.rechargeSelect.addEventListener('change', function (event) {
      const selected = event.target.value;
  
      const oneTime = widget.querySelector(selectors.oneTime)
      const subSave = widget.querySelector(selectors.subSave)
      const plansDropdown = widget.querySelector(selectors.plansDropdown)
      
      if (selected === '') {
        if (oneTime) oneTime.click()
        // Reset to original compare price for one-time purchase
        widget.updateComparisonPrice(0);
      } else {
        if (subSave) subSave.click()
  
        if (plansDropdown) {
          const options = plansDropdown.options;
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === selected) {
              options[i].selected = true;
              options[i].setAttribute('selected', true);
              break;
            }
          }
          plansDropdown.value = selected
        }
        
        // Update comparison price based on selected subscription plan
        widget.updateComparisonPrice(selected);
      }
    });
  }
  


  updateComparisonPrice = (selectedPlanId) => {
    try {
      // Get product data from script tag
      const productScriptTag = document.getElementById('product-data-all');
      const productData = productScriptTag ? JSON.parse(productScriptTag.textContent.trim()) : null;
      
      if (!productData) return;
      
      // Get the compare price element
      const comparePriceElement = document.querySelector('.product-item__compare');
      if (!comparePriceElement) return;
      
      // Store the original price if not already stored
      if (this.originalComparePrice === null) {
        const priceText = comparePriceElement.textContent.replace('$', '');
        this.originalComparePrice = parseFloat(priceText);
        comparePriceElement.setAttribute('data-original-price', this.originalComparePrice);
      }
      
      // Get original price
      const comparePrice = this.originalComparePrice || 
                          parseFloat(comparePriceElement.getAttribute('data-original-price'));
      
      if (isNaN(comparePrice)) return;
      
      // Default discount percentage
      let discountPercentage = 0;
      
      // If a subscription plan is selected, find the matching plan and its discount
      if (selectedPlanId && selectedPlanId !== '0' && selectedPlanId !== '') {
        // Loop through all selling plan groups
        const sellingPlanGroups = productData.selling_plan_groups || productData;
        
        for (const group of sellingPlanGroups) {
          // Loop through all selling plans in the group
          for (const plan of group.selling_plans || []) {
            // Check if this is the selected plan
            if (plan.id && plan.id.toString() === selectedPlanId.toString()) {
              // Get discount percentage from price adjustments
              if (plan.price_adjustments && plan.price_adjustments.length > 0) {
                const adjustment = plan.price_adjustments.find(adj => 
                  adj.value_type === 'percentage'
                );
                
                if (adjustment) {
                  discountPercentage = adjustment.value;
                }
              }
              break;
            }
          }
        }
      }
      
      // Apply the discount to the original price or restore original price
      if (discountPercentage > 0) {
        // Calculate discounted price
        const discountedPrice = comparePrice * (1 - discountPercentage / 100);
        comparePriceElement.textContent = '$' + discountedPrice.toFixed(2);
      } else {
        // Reset to original price
        comparePriceElement.textContent = '$' + comparePrice.toFixed(2);
      }
      
    } catch (error) {
      console.error('Error updating comparison price:', error);
    }
  }



handleSelectChange = () => {
  if (!this.rechargeSelect) return

  const widget = this
  this.rechargeSelect.addEventListener('change', function (event) {
    const selected = event.target.value;

    const oneTime = widget.querySelector(selectors.oneTime)
    const subSave = widget.querySelector(selectors.subSave)
    const plansDropdown = widget.querySelector(selectors.plansDropdown)
    
    if (selected === '') {
      if (oneTime) oneTime.click()
      // Reset to original compare price for one-time purchase
      widget.updateComparisonPrice(0);
    } else {
      if (subSave) subSave.click()

      if (plansDropdown) {
        const options = plansDropdown.options;
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === selected) {
            options[i].selected = true;
            options[i].setAttribute('selected', true);
            break;
          }
        }
        plansDropdown.value = selected
      }
      
      // Update comparison price based on selected subscription plan
      widget.updateComparisonPrice(selected);
    }
  });
}
}

window.customElements.define("widget-recharge", widgetRecharge)